@import '/node_modules/bootstrap/scss/functions';
@import '/node_modules/bootstrap/scss/variables';
@import '/node_modules/bootstrap/scss/mixins';
@import '/node_modules/bootstrap/scss/grid';
@import '/node_modules/bootstrap/scss/navbar';
@import '/node_modules/bootstrap/scss/forms';
@import '/node_modules/bootstrap/scss/alert';
@import 'bootstrap-customize.scss';

@import '/node_modules/ngx-bootstrap/datepicker/bs-datepicker';
@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import '/node_modules/@fortawesome/fontawesome-free/css/all';

@import 'variables';
@import 'global';
@import 'color';
@import 'icons';
@import 'cursor';
@import 'bs-datepicker-override.scss';

body {
    background-color: $project-background;
}
