@import './color.scss';

.container {
    padding-bottom: 2rem;
    margin: 0 1rem;
    max-width: calc(100% - 2rem);
}

* {
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
    color: $text-color;
}

small {
    color: $text-lightest;
    font-size: 12px;
    line-height: 8px;
}

small.small-2 {
    color: $text-lightest;
    font-size: 10px;
    line-height: 9px;
}

.bold {
    font-weight: bold;
}

h1 {
    font-size: 24px;
    line-height: 36px;
}

h2 {
    font-size: 20px;
    line-height: 30px;
}

p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 16px;

    &.white {
        color: white;
    }
}

label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 16px;
}

.tabs {
    display: flex;
    padding-top: 25px;
    border-bottom: solid 4px $blue-main-color;
    margin-bottom: 1rem;

    .tab {
        height: 48px;
        width: 150px;
        margin-right: 5px;
        border-radius: 2px 2px 0 0;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;

        p {
            text-align: center;
            line-height: 48px;
        }

        &.active {
            background-color: $blue-main-color;
            box-shadow: none;

            p {
                color: white;
            }
        }

        &.disabled > p {
            color: #c2c6cb;
        }
    }
}

button {
    width: 150px;
    height: 35px;
    border: none;
    border-radius: 4px;
    background-color: $blue-main-color;
    color: white;
    font-size: 14px;

    &.secondary {
        background-color: $orange-secondary-color;
    }

    &.white {
        background-color: white;
        border: solid 1px $blue-main-color;
        color: $blue-main-color;
    }

    &.disabled {
        pointer-events: none;
        color: white;
        background-color: #d4d7da;
    }
}

.small {
    font-size: 12px;
}

.large {
    font-size: 18px;
}

.xx-large {
    font-size: 30px;
}

.icon-lg {
    width: 30px !important;
}

label.required::after {
    content: ' *';
    color: #ff010a;
}

.has-required {
    border: solid 1px #ff0000 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8a9bad !important;
}
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #8a9bad !important;
}
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #8a9bad !important;
}
