$blue-main-color: #1565c0;
$orange-secondary-color: #ff893e;
$dark-blue-font-color: #344251;
$img-contain-background: #838896;
$project-background: #f1f2f7;

$text-color: #344251;
$text-light: #56697e;
$text-lightest: #8a9bad;
$text-small: #8c9cae;
