@font-face {
    font-family: 'icon';
    src: url('~src/assets/fonts/icon.eot?z1f7nh');
    src: url('~src/assets/fonts/icon.eot?z1f7nh#iefix') format('embedded-opentype'),
        url('~src/assets/fonts/icon.ttf?z1f7nh') format('truetype'),
        url('~src/assets/fonts/icon.woff?z1f7nh') format('woff'),
        url('~src/assets/fonts/icon.svg?z1f7nh#icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-data-people:before {
    content: '\e901';
}

.icon-data-product:before {
    content: '\e902';
}

.icon-arrow-back:before {
    content: '\e903';
}

.icon-arrow-next:before {
    content: '\e904';
}

.icon-condition:before {
    content: '\e900';
}

.icon-arrow-down:before {
    content: '\e90a';
}

.icon-arrow-up:before {
    content: '\e90b';
}

.icon-bars:before {
    content: '\e90c';
}

.icon-calendar:before {
    content: '\e90d';
}

.icon-catalouge:before {
    content: '\e90e';
}

.icon-check-solid:before {
    content: '\e90f';
}

.icon-check-square:before {
    content: '\e910';
}

.icon-clock:before {
    content: '\e911';
}

.icon-close-circle:before {
    content: '\e912';
}

.icon-close1:before {
    content: '\e913';
}

.icon-correct:before {
    content: '\e91b';
}

.icon-dashboard:before {
    content: '\e91c';
}

.icon-detail:before {
    content: '\e91d';
}

.icon-flight-arrival:before {
    content: '\e91e';
}

.icon-flight-go:before {
    content: '\e91f';
}

.icon-garbage:before {
    content: '\e920';
}

.icon-history:before {
    content: '\e921';
}

.icon-location:before {
    content: '\e922';
}

.icon-logout:before {
    content: '\e923';
}

.icon-management:before {
    content: '\e924';
}

.icon-moon:before {
    content: '\e925';
}

.icon-plus-circle:before {
    content: '\e926';
}

.icon-report:before {
    content: '\e927';
}

.icon-star:before {
    content: '\e928';
}

.icon-image-default {
    content: url('~src/assets/icons/icon-image-default.svg');
    width: 1rem;
}

.icon-file-word {
    content: url('~src/assets/icons/icon-file-word.svg');
    width: 1rem;
}

.icon-file-pdf {
    content: url('~src/assets/icons/icon-file-pdf.svg');
    width: 1rem;
}

.icon-file-pdf-blue {
    content: url('~src/assets/icons/icon-file-pdf-blue.svg');
    width: 1rem;
}

.icon-file-word-disable {
    content: url('~src/assets/icons/icon-file-word-disable.svg');
    width: 1rem;
}

.icon-file-pdf-disable {
    content: url('~src/assets/icons/icon-file-pdf-disable.svg');
    width: 1rem;
}

.icon-flight-go {
    content: url('~src/assets/icons/icon-flight-go.svg');
    width: 1rem;
}

.icon-commision {
    content: url('~src/assets/icons/icon-commision.svg');
    width: 1rem;
}

.icon-management {
    content: url('~src/assets/icons/icon-management.svg');
    width: 1rem;
}

.icon-location {
    content: url('~src/assets/icons/icon-location.svg');
    width: 1rem;
}

.icon-clock-2 {
    content: url('~src/assets/icons/icon-clock.svg');
    width: 1rem;
}

.icon-calendar-2 {
    content: url('~src/assets/icons/icon-calendar.svg');
    width: 1rem;
}

.icon-dashboard-white {
    content: url('~src/assets/icons/icon-dashboard-white.png');
    width: 1rem;
}
