.btn-primary {
    color: #fff;
    background-color: #1565c0;
    border-color: #1565c0;
}

.btn-link {
    color: #56697e;
    text-decoration: underline;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    color: #8a9bad;
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    color: #8a9bad;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 14px;
    color: #8a9bad;
}

.dropdown-item {
    font-size: 14px;
    color: #56697e;
    border-radius: 0;

    &:hover,
    &:focus {
        font-weight: bold;
        color: #56697e;
        background-color: #aad5ff;
    }
}

.modal-delete-period {
    max-width: 440px !important;
    .modal-content {
        height: 332px !important;
    }
}

.modal-success {
    max-width: 350px !important;
    .modal-content {
        height: 260px !important;
        border-radius: 10px;
        border: 0px;
    }
}
.modal-cancel {
    max-width: 352px !important;

    .modal-content {
        height: 243px !important;
        border-radius: 10px;
        border: 0px;
    }
}

.modal-delete-tag {
    max-width: 360px !important;

    .modal-content {
        height: 260px !important;
        border-radius: 10px;
        border: 0px;
    }
}

.delete-programtour-modal {
    .modal-content {
        width: 365px;
        min-height: 285px;
    }

    .tourBanner {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        img {
            min-width: 120px;
        }
    }

    .tourInfo {
        flex: 1;
    }

    .tourCodeLabel {
        font-size: 12px;
        color: #8a9bad;
    }

    .tourName {
        font-size: 12px;
        font-weight: 400;
        color: #56697e;
    }
}
