.bs-datepicker-head {
    background-color: #eeeeee !important;

    span {
        color: #54708b !important;
    }
}

.bs-datepicker-body {
    border-color: #eeeeee !important;
}